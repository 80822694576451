/** @jsx jsx */
import { jsx } from 'theme-ui'

import PropTypes from 'prop-types'

import { ReactComponent as Elfnulleins } from '../images/elfnulleins.svg'

const Logo = ({ as: Tag, className, ...props }) => {
  return (
    <Tag
      className={`${className}`}
      sx={{
        width: '306px',
        height: '78px',
      }}
      {...props}
    >
      <Elfnulleins />
    </Tag>
  )
}

Logo.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
}

Logo.defaultProps = {
  as: 'div',
  className: '',
}

export default Logo
