import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Header from './Header'
import Footer from './Footer'
import { useHeaderOffset } from '../hooks/useHeaderOffset'

const Layout = props => {
  const { path } = props || {}
  const isHome = path !== '/'
  const { t } = useTranslation()
  const { children } = props
  const headerOffset = useHeaderOffset()

  const navItems = t('navigation.items', {
    returnObjects: true,
  })

  const directoryItems = t('directory.items', {
    returnObjects: true,
  })

  if (isHome) {
    const parseLink = link => {
      if (link.url.startsWith('#')) {
        link.url = `/${link.url}`
      }
    }

    navItems.forEach(item => parseLink(item))
    directoryItems.forEach(item => parseLink(item))
  }

  return (
    <>
      <Header navItems={navItems} headerOffset={headerOffset} isHome={isHome} />
      {children}
      <Footer
        directoryItems={directoryItems}
        headerOffset={headerOffset}
        isHome={isHome}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
