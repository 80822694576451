import { useState, useEffect } from 'react'

export const useHeaderOffset = () => {
  const [headerOffset, setHeaderOffset] = useState(0)

  useEffect(() => {
    if (window.matchMedia('(min-width: 640px)').matches) {
      setHeaderOffset(-126)
    }
  }, [])

  return headerOffset
}
