import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            defaultDescription
            defaultImage
            defaultLang
            defaultTitle
            siteTitle
            siteUrl
            titleTemplate
          }
        }
      }
    `
  )

  return site.siteMetadata
}

export { useSiteMetadata }
