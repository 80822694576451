/** @jsx jsx */
import { jsx } from 'theme-ui'

import PropTypes from 'prop-types'

const AspectRatio = ({
  as: Tag,
  className,
  children,
  width,
  height,
  allowOverflow,
  ...props
}) => {
  const overflowCss = allowOverflow ? '' : 'overflow-hidden'
  const childCss = allowOverflow ? '' : 'absolute inset-0'

  return (
    <Tag
      className={`relative grid grid-cols-1 grid-rows-1 ${overflowCss} ${className}`}
      sx={{
        '> *': {
          gridArea: '1 / 1 / 2 / 2',
        },
      }}
      {...props}
    >
      <svg
        className="w-full invisible"
        viewBox={`0 0 ${width} ${height}`}
      ></svg>
      <div className={childCss}>{children}</div>
    </Tag>
  )
}

AspectRatio.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  allowOverflow: PropTypes.bool,
}

AspectRatio.defaultProps = {
  as: 'div',
  className: '',
  width: 16,
  height: 9,
  allowOverflow: false,
}

export default AspectRatio
