/** @jsx jsx */
import { jsx } from 'theme-ui'

import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'gatsby'

import Logo from '../components/Logo'
import AspectRatio from '../components/AspectRatio'

import { ReactComponent as ArrowDownIcon } from '../images/arrow_down.svg'

const Footer = ({
  className,
  headerOffset,
  directoryItems,
  isHome,
  ...props
}) => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  const linkClasses = 'block text-headline3 leading-none py-4 cursor-pointer'

  const directoryElems = Array.isArray(directoryItems)
    ? directoryItems.map(item => (
        <li key={item.text}>
          {/^\/(?!\/)/.test(item.url) ? (
            <Link to={item.url} className={linkClasses}>
              {item.text}
            </Link>
          ) : (
            <ScrollLink
              to={item.url.substring(1)}
              smooth={true}
              offset={headerOffset}
              className={linkClasses}
            >
              {item.text}
            </ScrollLink>
          )}
        </li>
      ))
    : []

  const logoLink = isHome ? (
    <Link to="/" className="block text-white" title="ElfNullEins">
      <Logo />
    </Link>
  ) : (
    <ScrollLink
      to="top"
      smooth={true}
      className="block text-white cursor-pointer"
    >
      <Logo />
    </ScrollLink>
  )

  return (
    <footer>
      <section className="py-14 sm:py-24 text-white bg-black">
        <div className="mx-auto px-container max-w-container">
          {logoLink}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-14 sm:mt-24 md:mt-44">
            <div className="col-start-1 col-span-1">
              <ul>{directoryElems}</ul>
            </div>
          </div>
          <div className="sm:flex sm:justify-between mt-14 sm:mt-24 md:mt-44 text-headline3 leading-none">
            <div>{t('copyright', { currentYear })}</div>
            <div className="mt-8 sm:mt-0">
              <ScrollLink
                className="flex cursor-pointer"
                to="top"
                smooth={true}
              >
                <span
                  className="block text-black bg-white rounded-full"
                  sx={{ padding: '0.125em 1em' }}
                >
                  {t('to_top_button.text')}
                </span>
                <span>
                  <AspectRatio
                    className="block ml-4 transform rotate-180"
                    width={144}
                    height={202}
                    sx={{
                      width: '0.89em',
                    }}
                  >
                    <ArrowDownIcon />
                  </AspectRatio>
                </span>
              </ScrollLink>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

Footer.defaultProps = {
  className: '',
}

export default Footer
