/** @jsx jsx */
import { jsx } from 'theme-ui'

import PropTypes from 'prop-types'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'gatsby'

import Logo from '../components/Logo'

const Header = ({ className, headerOffset, navItems, isHome, ...props }) => {
  const linkClasses =
    'block md:text-headline3 leading-none cursor-pointer rounded-full whitespace-no-wrap transition-colors duration-300'
  const linkSX = {
    padding: ['0.25em 0.75em', '0.25em 1em'],
  }

  const isInternal = url => /^\/(?!\/)/.test(url)

  const navElems = Array.isArray(navItems)
    ? navItems.map((item, index) => (
        <li className={index > 0 ? `md:ml-4` : ``} key={item.text}>
          {isInternal(item.url) ? (
            <Link to={item.url} className={linkClasses} sx={linkSX}>
              {item.text}
            </Link>
          ) : (
            <ScrollLink
              to={item.url.substring(1)}
              activeClass="text-white bg-black"
              smooth={true}
              spy={true}
              offset={headerOffset}
              className={linkClasses}
              sx={linkSX}
            >
              {item.text}
            </ScrollLink>
          )}
        </li>
      ))
    : []

  const logoLink = isHome ? (
    <Link to="/" className="block mt-8 sm:mb-4" title="ElfNullEins">
      <Logo />
    </Link>
  ) : (
    <ScrollLink
      to="top"
      smooth={true}
      className="block mt-8 sm:mb-4 cursor-pointer"
    >
      <Logo />
    </ScrollLink>
  )

  return (
    <header className="sm:sticky top-0 z-nav bg-white elevation-4">
      <div className="sm:flex sm:justify-between sm:items-center mx-auto px-8">
        {logoLink}
        <nav className="py-4 sm:py-8 sm:ml-8">
          <ul className="flex justify-bewteen">{navElems}</ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

Header.defaultProps = {
  className: '',
}

export default Header
