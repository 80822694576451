import React from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'

import { useSiteMetadata } from '../hooks/useSiteMetadata'

const SEO = ({ article, description, image, lang, noindex, title, theme }) => {
  const {
    defaultDescription,
    defaultImage,
    defaultLang,
    defaultTitle,
    siteTitle,
    siteUrl,
    titleTemplate,
    twitterUsername,
  } = useSiteMetadata()

  const pageImage = image || defaultImage
  const pageDescription = description || defaultDescription
  const pageTitle = title || defaultTitle
  const pageLang = lang || defaultLang
  const pageType = article ? 'article' : 'website'

  const htmlClasses = []
  htmlClasses.push(theme ? `theme-${theme}` : '')

  return (
    <Helmet
      htmlAttributes={{ lang: pageLang, class: htmlClasses.join(' ') }}
      defaultTitle={siteTitle}
      title={pageTitle}
      titleTemplate={titleTemplate}
    >
      {pageDescription && <meta name="description" content={pageDescription} />}
      {pageImage && <meta property="image" content={pageImage} />}
      {siteUrl && <meta property="og:url" content={siteUrl} />}
      <meta property="og:title" content={pageTitle} />
      {description && <meta property="og:description" content={description} />}
      {siteTitle && <meta property="og:site_name" content={siteTitle} />}
      {pageImage && <meta property="og:image" content={pageImage} />}
      <meta name="og:type" content={pageType} />
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:site" content={`@${twitterUsername}`} />
      )}
      {twitterUsername && (
        <meta name="twitter:creator" content={`@${twitterUsername}`} />
      )}
      {!!noindex && <meta name="robots" content="noindex" />}

      <body className="text-body leading-tight" id="top" />
    </Helmet>
  )
}

SEO.propTypes = {
  article: PropTypes.bool,
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  noindex: PropTypes.bool,
  title: PropTypes.string,
  theme: PropTypes.string,
}

SEO.defaultProps = {
  article: false,
  description: null,
  image: null,
  lang: null,
  noindex: false,
  title: null,
  theme: null,
}

export default SEO
